.app {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: auto;
  scroll-behavior: smooth;

  .landing-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .image-slogan-container {
      width: 100%;
      height: 72vh;
      position: relative;

      .drop-image {
        width: 100%;
        height: 100%;
      }

      .slogan {
        font-size: 2vw;
        font-weight: 600;
        margin: 0;
        position: absolute;
        bottom: 8vh;
        right: 2vw;
        text-align: right;
        background-color: rgba(255, 255, 255, .5);
        padding: 1.5vh;
        border-radius: 4px;
      }

    }

    .industries-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding: 5vh 0 0 0;
      margin: 0 0 10vh 0;

      .title {
        font-size: 2.8vw;
        font-weight: 500;
        letter-spacing: 3px;
        font-family: 'vista-sans-sc';
        width: 97%;
        text-align: center;
        padding: 3vh 0 0 0;
        margin: 0 0 10vh 0;
      }

      .images-container {
        width: 98.5%;
        height: 30vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        .image-container {
          width: 19%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          margin: 0 .5%;

          .image {
            width: 100%;
            min-height: 23.5vh;
          }

          .description {
            font-size: 1.2vw;
            font-weight: 600;
            margin: 3vh 0 0 0;
            text-align: center;
          }
        }
      }
    }

    .difference-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      margin: 10vh 0 0 0;

      .title {
        font-size: 2.5vw;
        font-weight: 500;
        letter-spacing: 3px;
        font-family: 'vista-sans-sc';
        width: 95%;
        text-align: center;
        padding: 15vh 0 0 0;
        margin: 0 0 5vh 0;
        border-top: 3px solid rgb(179, 179, 179);
      }

      .alfa-description {
        width: 55%;
        line-height: 3.8vh;
        text-align: center;
        margin: 0 0 8vh 0;
        font-size: 1.5vw;
        font-weight: 400;
      }

      .alfa-icon {
        font-size: 5vw;
        color: rgb(0, 0, 0);
        margin: 0 0 4vh 0;
      }

      .items-container {
        width: 97%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;

        .item-container {
          width: 33%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          margin: 0 1%;

          .icon {
            font-size: 5vw;
            color: rgb(0, 0, 0);
          }

          .name {
            font-size: 1.3vw;
            font-weight: 600;
            margin: 3vh 0 0 0;
            text-align: center;
          }

          .description {
            width: 70%;
            font-size: 1.2vw;
            font-weight: 400;
            line-height: 3vh;
            margin: 3vh 0 0 0;
            text-align: center;
          }
        }
      }
    }
  }
}
