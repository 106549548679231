.product-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .image-title {
    width: 90%;
    margin: 8vh 0 10vh 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .carousel {
      width: 400px !important;
      height: 250px !important;
      position: relative;
      z-index: 0;

      img {
        height: 250px;
        display: block;
        margin: auto;
      }
    }

    .info {
      margin-left: 3vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .logo {
        width: 9vw;
      }
  
      .product-name {
        font-size: 18px;
        margin: 0 0 2vh 0;
      }

      .text {
        font-size: 15px;
        margin: .5vh 0 0 0;
      }
    }

  }

  .tables {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;;
  }

  table {
    border-collapse: collapse;

    .header {
      border: .5px solid black;
      padding: .8vh .5vw;
    }

    .cell {
      border: .5px solid black;
      text-align: center;
      font-family: 'Courier New', Courier, monospace;
    }
  }

  .margin-right {
    margin: 0 2vw 0 0;
  }

  .product-description {
    width: 90%;
    align-items: flex-start;

    .title {
      font-size: 18px;
    }

    .text {
      margin: .5vh 0 0 0;
    }
  }
}