.navbar {
    width: 100%;
    z-index: 2;
    height: 28vh;
    background-color: rgba(255, 255, 255, .4);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .logo {
        height: 70%;
        margin: 0 0 0 3vw;
        cursor: pointer;
    }

    .menu-container {
        width: 95%;
        height: 30%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        border-top: 3px solid rgb(179, 179, 179);

        .menu-option {
            width: 20%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;

            .border-bottom {
                width: 100%;
                background-color: transparent;
                border-bottom: 1px solid rgb(179, 179, 179);
                position: absolute;
                bottom: 0;
                left: 0;
                cursor: pointer;
            }

            .value {
                width: 100%;
                font-size: 1.2vw;
                font-weight: 600;
                font-family: 'vista-sans-sc';
                text-align: center;
                cursor: pointer;
            }

            .dropdown-menu {
                background-color: rgb(255, 255, 255);
                position: absolute;
                left: 0;
                top: 100%;
                z-index: 5;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .dropdown-menu-option {
                    width: 100%;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    &:hover {
                        background-color: rgb(238, 238, 238);
                    }

                    .dropdown-menu-option-text {
                        display: inline-block;
                        width: max-content;
                        cursor: pointer;
                        padding: 2.5vh 1.5vw;
                        margin: 0;
                        font-size: 1.1vw;
                        font-weight: 500;
                    }

                    img {
                        width: 15%;
                        cursor: pointer;
                        margin: 0 1vw 0 0;
                    }
                }
            }
        }
    }
}