.alert-view {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  top: 0;

  .alert-container {
    width: 24vw;
    height: 20vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4.5vh;
    justify-content: center;
    padding: 4vh 3vw;
    position: relative;

    .text {
      color: #000000;
      text-align: center;
      width: 100%;
      margin: 0 0 2vh 0;
      font-size: 1.3vw;
    }

    .bold {
      font-weight: 600;
    }

    .button {
      height: 4.5vh;
      width: 16vw;
      border-radius: 1.7vh;
      border-color: transparent;
      cursor: pointer;
      border: 0.5px solid #000000;
      background-color: #000000;
      color: #ffffff;
      font-size: 1.2vw;
      margin: 2vh 0 0 0;
    }
  }
}
