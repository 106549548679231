.landing-mobile-container {
  width: 100vw;
  padding: 0 0;
  position: relative;

  .info-container {
    width: 100%;
    height: 90vh;
    margin: 0 0 10vh 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      width: 90%;
    }

    .name {
      margin: 1vh 0 0 0;
      font-weight: 700;
      text-align: center;
    }

    .position {
      margin: 0;
      font-weight: 300;
      font-style: italic;
      text-align: center;
    }

    .separator-container {
      width: 100%;
      margin-top: 5vh;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .separator {
        width: 30%;
        margin: 2vh 0;
      }

      .left {
        width: 60%;

        p {
          display: flex;
          align-items: center;
          font-size: 18px;
          .icon {
            margin-right: 10px;
          }
        }
      }

      .right {
        padding: 2vh 0 0 0;
        width: 60%;

        p {
          display: flex;
          align-items: center;
          font-size: 18px;
          margin: 4px 0 0 0;
          .icon {
            margin-right: 10px;
          }
        }

        .margin {
          margin: 4px 0 0 28px;
        }
      }
    }

    .save-contact {
      padding: 5vw;
      margin: 7vh 0 0 0;
      border: 1px solid rgb(210, 210, 210);
      border-radius: 10px;
      cursor: pointer;
    }

    .down-icon {
      position: absolute;
      bottom: 10px;
      font-size: 30px;
    }
  }

  .images {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 80%;
    }

    .image-slogan-container {
      width: 100%;
      height: fit-content;
      position: relative;

      .slogan {
        font-size: 2vw;
        font-weight: 600;
        margin: 0;
        position: absolute;
        bottom: 20px;
        right: 20px;
        text-align: right;
        background-color: rgba(255, 255, 255, .5);
        padding: 1.5vh;
        border-radius: 4px;
      }
    }

    .image {
      width: 100%;
    }
  }
}