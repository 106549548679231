.contact-us-view {
  display: flex;
  position: fixed;
  z-index: 25;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0,0,0,0.6);

  .contact-us-content {
    background-color: #FFFFFF;
    margin: auto;
    padding: 5vh 0;
    width: 36vw;
    height: 62vh;
    border-radius: 2vw;
    position: relative;

    &:-webkit-scrollbar {
      display: none;
    }

    .close-icon-container {
      position: absolute;
      z-index: 5;
      right: .5vw;
      top: 2vh;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .close-icon {
        color: #000000;
        font-size: 2vw;
        padding: 0 1vw;
        cursor: pointer;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .contact-us-main {
      position: relative;
      z-index: 3;
      margin: 0 3vw;
      padding: 0 3vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      overflow-y: auto;

      .title {
        font-size: 1.2vw;
        font-weight: 600;
        margin: 0;
      }

      .info-container {
        width: 100%;

        .input-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          p {
            font-size: 1.1vw;
            font-weight: 400;
            margin: 2vh 0 1vh 0;
          }

          input {
            width: 90%;
            height: 4.5vh;
            padding: 0 5%;
            border: 0.5px solid #000000;
            border-radius: 10px;
            background-color: #ffffff;
            font-size: 1vw;
            cursor: text;

            &::placeholder {
              font-size: 1vw;
            }
          }

          textarea {
            width: 90%;
            padding: 1vh 5%;
            border: 0.5px solid #000000;
            border-radius: 10px;
            height: 15vh;
            font-size: 1vw;
            background-color: #ffffff;
            resize: none;
            cursor: text;

            &::placeholder {
              font-size: 1vw;
            }
          }
        }
      }

      button {
        width: 40%;
        height: 4.5vh;
        border: none;
        border-radius: 10px;
        margin: 0;
        background-color: #000000;
        color: #ffffff;
        cursor: pointer;
      }

      .disabled {
        opacity: .4;
      }
    }
  }
}