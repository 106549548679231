@font-face {
  font-family: 'vista-sans';
  src: url('../assets/fonts/VistaSansLight.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'vista-sans';
  src: url('../assets/fonts/VistaSansBook.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'vista-sans-sc';
  src: url('../assets/fonts/VistaSansSCBook.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'vista-sans';
  src: url('../assets/fonts/VistaSansMed.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'vista-sans';
  src: url('../assets/fonts/VistaSansBold.ttf') format('truetype');
  font-weight: 900;
}