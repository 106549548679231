.main-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer {
    width: 95%;
    min-height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15vh 0 0 0;
    padding: 2vh 0;
    border-top: 3px solid rgb(179, 179, 179);
    position: relative;

    .logo {
      width: 20%;
    }

    .alfaproducts-information-container {
      width: 13.5%;
      height: 10vh;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .information {
        font-size: 1vw;
        font-weight: 300;
        text-align: center;
        margin: 0;
        cursor: pointer;
      }

      .contact-us-text {
        font-weight: 400;
      }
    }
  }

  .gray-bar {
    width: 100%;
    min-height: 10vh;
    background-color: rgb(46, 46, 46);
  }
}