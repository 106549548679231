@import './Fonts.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

* {
  font-family: 'vista-sans';

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: default;
  }
}
